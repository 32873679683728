/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */
import { SpotSearchController } from './spotSearch-controller';
import { SpotDetailController } from './spotDetail-controller';
import { SpotGridController } from './spotGrid-controller';
var dependencies = ['comcast.common.authentication', 'comcast.common.communication', 'comcast.common.navigation', 'comcast.common.notification.unsaved', 'comcast.spots', 'ngMaterial', 'ngMessages', 'comcast.directives', 'ui.router'];
var defaultName = angular.module('comcast.spots.detail', dependencies).config(['$stateProvider', function ($stateProvider) {
  $stateProvider.state('spotList', {
    // Specify params that this state can access from the URL
    url: '/list?format&title&isci&SpotsStatus&orderNumber&promo&nonBroadcastMedia&duration&createdDateFrom&createdDateTo&updatedDateFrom&updatedDateTo&validFrom&validTo&advertiserId&agencyId&brandId&includeArchives&clearedFromDate&dateUpdated&clientTier',
    parent: 'spots',
    // Specify default values for state params
    params: {
      title: null,
      isci: null,
      format: null,
      status: null,
      orderNumber: null,
      promo: null,
      nonBroadcastMedia: null,
      duration: null,
      createdDate: null,
      updatedDate: null,
      validFrom: null,
      advertiser: null,
      agency: null,
      brand: null,
      includeArchives: null,
      sortField: null,
      sortDirection: null,
      clearedFromDate: null,
      notes: null,
      clientTier: null
    },
    reloadOnSearch: true,
    views: {
      'spotContent@spots': {
        template: '<ui-view name="detailContent" class="detail-content"/>'
      },
      'spotSearch@spots': {
        template: '<ui-view name="detailSearch" class="detail-search" />'
      },
      'detailContent@spotList': {
        template: require('./spotGrid-template.html'),
        controller: SpotGridController,
        controllerAs: 'vm'
      },
      'detailSearch@spotList': {
        template: require('./spotSearch-template.html'),
        controller: SpotSearchController,
        controllerAs: 'vm'
      }
    },
    access: {
      requiresLogin: true,
      requiredPermissions: ['User'],
      permissionCheckType: 'AtLeastOne'
    }
  }).state('spotDetail', {
    url: '/spots/:id',
    parent: 'spotList',
    params: {
      gridData: null,
      pageData: null
    },
    views: {
      detailContent: {
        template: require('./spotDetail-template.html'),
        controller: SpotDetailController,
        controllerAs: 'vm'
      }
    },
    access: {
      requiresLogin: true,
      requiredPermissions: ['User'],
      permissionCheckType: 'AtLeastOne'
    }
  });
}]).name;
export { defaultName as default, dependencies };