/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */

declare var angular: angular.IAngularStatic;

import * as moment from 'moment';
import * as bowser from 'bowser';
import * as Flow from '@flowjs/flow.js';

import { StatusConstant } from '../../constants/status.constant';
import { AuthConstant } from '../../constants/auth.constant';

//// CONTROLLER ////
import { QcRejectionDialogController } from './dialogs/qcRejectionDialog-controller';
import { RequestCCDialogController } from './dialogs/requestCCDialog-controller';
import { AssignDialogController } from './assignDialog-controller';

//// CONTROLLER ////
export class SpotDetailController {
    // Private
    private _statusConstants: any;
    private _authConstants: any;
    private newAgency: boolean = false;
    private newAdvertiser: boolean = false;
    private newBrand: boolean = false;

    // Bindable
    public delivery: any;
    public spotStatuses: any;
    public deliveriesStatuses: any;
    public spot: any;
    public assetMap: any;
    public proxyAsset: any;
    public needsConversion: any;
    public displayError: any;
    public polling: any;
    public asset: any;
    public sessionData: any;
    public accountFriends: any;
    public isFranchise: any;
    public downloadLink: any;
    public loading: any;
    public storyBoard: any;
    public agencies: any;
    public brands: any;
    public isciValidator: any;
    public MAX_ISCI_LENGTH: any;
    public oneMonthFromToday: any;
    public uploading: any;
    public validToOpen: any;
    public validFromOpen: any;
    public newSpot: any;
    public filteredDuration: any;
    public validDuration: any;
    public assign: any;
    public isFriendOf: any;
    public originalSpot: any;
    public friendList: any;
    public currentProgress: any;
    public fileToUpload: any;
    public isFriend: any;
    public isciChanged: any;
    public notNewAndIsFriend: any;
    public hasProdVendor: any;
    public invalidFileUploaded: any;
    public offsiteRetrieval: any;
    public modifiedNotes: any;
    public downloadBaseUri: any;
    public asperaAccountDetails: any;
    public asperaEventObj: any;
    public showThumbnailStrip: any;
    public useTapIngest: Boolean = true;
    public spotFile: any;
    public capturedId: any;
    public pollForFlip: any;
    public spotFileId: any;
    public activeUploads: any;
    public reloadingSpotStatus: any;
    public storyboard: any;
    public capturedIsci: any;
    public adIdLoading: boolean = false;
    public detailForm: any;
    public unusualAudios: any = [];
    public formats: any = [];
    public promoTypes: any = [];
    public downConvertPreferences: any = [];
    public proxyType: any;
    public airStatus: any;
    public hasPermission: any;
    public reloadOnFailure: boolean = false;
    public nonFormatIsci: boolean = false;

    static get $inject() {
        return [
            '$interval',
            '$mdDialog',
            '$q',
            '$rootScope',
            '$scope',
            '$state',
            '$stateParams',
            '$timeout',
            '$window',
            'AdvertiserResourceFactory',
            'AgencyResourceFactory',
            'AssetResourceFactory',
            'BrandsResourceFactory',
            'AuthenticationResourceFactory',
            'EndPointService',
            'EnumService',
            'loginService',
            'NotificationService',
            'socket',
            'SpotResourceFactory',
            'submitManager',
            '$filter',
            'SecurityAccountResourceFactory',
            'addelivery.event.constants',
            'StatusService',
            'RestrictFileService',
            'AsperaService',
        ];
    }

    constructor(
        public $interval: any,
        public $mdDialog: any,
        public $q: any,
        public $rootScope: any,
        public $scope: any,
        public $state: any,
        public $stateParams: any,
        public $timeout: any,
        public $window: any,
        public AdvertiserResourceFactory: any,
        public AgencyResourceFactory: any,
        public AssetResourceFactory: any,
        public BrandsResourceFactory: any,
        public AuthenticationResourceFactory: any,
        public EndPointService: any,
        public EnumService: any,
        public loginService: any,
        public NotificationService: any,
        public socket: any,
        public SpotResourceFactory: any,
        public submitManager: any,
        public $filter: any,
        public SecurityAccountResourceFactory: any,
        public EventConstants: any,
        public StatusService: any,
        public RestrictFileService: any,
        public AsperaService: any,
    ) {
        /* PRIVATE : DATA */
        //Declare all private variables here
        this._statusConstants = new StatusConstant();
        this._authConstants = new AuthConstant();

        /*
         * This is the `vm` object. It is a direct reference to the controller
         * which acts as our 'view-model' in angular. It also limits our need
         * to be accessing $scope directly. */
        /*jshint validthis:true*/
        let vm = this;
        this.pollForFlip = false;

        /* BINDABLE : DATA */
        this.delivery = {};
        this.spotStatuses = angular.copy(this._statusConstants.spots);
        this.deliveriesStatuses = angular.copy(this._statusConstants.deliveries);
        this.deliveriesStatuses.COMPLETED.status = 'Delivered';
        this.spot = null;
        this.assetMap = null;
        this.proxyAsset = null;
        this.needsConversion = false;
        this.displayError = false;
        this.polling = false;
        this.asset = null;
        this.sessionData = loginService.getSessionData();
        this.accountFriends = null;
        this.sessionData.accountType = vm.sessionData.accountType.replace(/_/g, '');
        this.isFranchise = vm.sessionData.franchiseCustomer;
        this.downloadLink = EndPointService.assetDownloadEndpoint;
        this.loading = {
            spot: false,
            dist: false,
            mezz: false,
            qt: false,
            mp3: false,
            vqc: false,
        };
        this.storyBoard = [];
        this.agencies = [];
        this.brands = [];
        this.isciValidator = /^[a-zA-Z0-9]+$/;
        this.MAX_ISCI_LENGTH =
            this.sessionData.account && vm.sessionData.account.strictIsci ? 15 : null;
        this.oneMonthFromToday = moment().add(1, 'months').format('MM/DD/YYYY');
        this.uploading = false;
        this.validToOpen = false;
        this.validFromOpen = false;
        this.newSpot = false;
        this.filteredDuration = null;
        this.validDuration = null;
        this.assign = null;
        this.isFriendOf = false;
        this.originalSpot = null;
        this.friendList = '';
        this.currentProgress = null;
        this.fileToUpload = {};
        this.isFriend = null;
        this.isciChanged = false;
        this.notNewAndIsFriend = false;
        this.hasProdVendor = false;
        this.invalidFileUploaded = false;
        this.offsiteRetrieval = false;
        this.modifiedNotes = false;
        this.downloadBaseUri = EndPointService.assetDownloadEndpoint;
        this.asperaAccountDetails = loginService.getSessionData().asperaAccountDetails;
        this.asperaEventObj = null;
        this.showThumbnailStrip = false;

        // Method alias
        this.hasPermission = loginService.hasPermission;

        /* EVENTS */
        //Register any event listeners
        $scope.$on('spotFileUploadReady', function (event: any, fileUploaded: any) {
            vm.invalidFileUploaded = false;
            if (vm.useTapIngest) {
                // TAP media ingest using FSRS and MIE directly
                vm.uploadMediaWithTap(fileUploaded);
            } else {
                // Standard HTTP upload to CAD via Node
                vm.uploadMedia(fileUploaded);
            }
        });

        $scope.$on('invalidFileUploaded', function () {
            vm.invalidFileUploaded = true;
            vm._stopIngest(false, false);
        });

        $scope.$on('submitUploadComplete', function (event: any, spot: any) {
            // This check should NEVER be necessary, since a user can only upload one at a time from this page, but better safe than sorry
            if (vm.spot.id === spot.id) {
                vm.currentProgress = null;

                vm.spot = angular.copy(spot);
                vm.uploading = false;
                vm.spot.statusBar = vm.StatusService.readSpotStatus(vm.spot);
                vm.spotFile = null;

                submitManager.removeActiveUpload(spot.id);
            }
        });

        socket.on('Spot_Ingest_Progress', function (ingestStatus: any) {
            if (
                (vm.spot && vm.spot.id && vm.spot.id !== -1) ||
                (vm.capturedId && vm.capturedId !== -1)
            ) {
                if (ingestStatus.spotId === vm.spot.id) {
                    $interval.cancel(vm.pollForFlip);

                    vm.spot.status = ingestStatus.ingestStatus;
                    if (
                        ['PROCESSING', 'VERIFYING'].indexOf(ingestStatus.ingestStatus) > -1
                    ) {
                        vm.currentProgress = ingestStatus.ingestPercent;
                        vm.spot.progress = ingestStatus.ingestPercent;
                    } else {
                        vm.currentProgress = null;
                    }

                    vm.spot.statusBar = vm.StatusService.readSpotStatus(vm.spot);

                    if (ingestStatus.ingestPercent === 100) {
                        vm._loadData(false, false);
                        vm.storyBoard = [];
                    }
                }
            }
        });

        $rootScope.$on('$stateChangeStart', function (
            event: any,
            toState: any,
            toParams: any
        ) {
            if (toState.name !== 'spotDetail') {
                $interval.cancel(vm.pollForFlip);
                vm.pollForFlip = null;
            }
            if (vm.uploading && !toState.name.match(/.*login.*/)) {
                event.preventDefault();
                let confirm = $mdDialog
                    .confirm()
                    .title('Would you like to continue?')
                    .textContent(
                        'Leaving the page while the file is still uploading will cancel the upload.'
                    )
                    .ariaLabel(
                        'Leaving the page while the file is still uploading will cancel the upload.'
                    )
                    .ok('Continue')
                    .cancel('Cancel');

                return $mdDialog.show(confirm).then(
                    function () {
                        // User said "Meh, whatever, let's leave"
                        vm.cancelImport();
                        $state.go(toState.name, toParams);
                        // transitionTo() promise will be rejected with
                        // a 'transition prevented' error
                    },
                    function () {
                        // User freaked out and said "NO! STAY HERE!"
                    }
                );
            } else if (toState.name.match(/.*login.*/)) {
                // If user is no longer authenticated, cancel the upload
                vm.cancelImport();
            }
        });

        /* RECURRING CODE */
        vm.pollForFlip = $interval(function () {
            vm._loadData(false, true);
        }, 120000);

        /* BAD SCOPE CODE */
        $scope.handleAsperaEvent = function (obj: any) {
            if (obj.status == 'failed') {
                vm.NotificationService.showNotificationToast(obj.error_desc);
                vm.AsperaService.handleCancel(obj);
                vm.$state.reload();
            }
            if (obj.status == 'running' || obj.status == 'initiating') {
                vm.asperaEventObj = obj;
                vm.spot.progress = Math.floor(obj.percentage * 100);
                vm.spot.status = 'UPLOADING';
                vm.spot.statusBar = vm.StatusService.readSpotStatus(vm.spot);
            }
            if (obj.status == 'completed') {
                vm.asperaEventObj = null;
                vm.spot.progress = Math.floor(obj.percentage * 100);
                vm.spot.statusBar = vm.StatusService.readSpotStatus(vm.spot);
                vm.pollForFlip = $interval(function () {
                    vm._loadData(false, false);
                }, 120000);
            }
        };
    }

    $onInit() {
        let vm = this;

        vm.$timeout(function () {
            vm.$rootScope.viewDetailForm = vm.$scope.viewDetailForm;
        });
        vm.SecurityAccountResourceFactory.findFriends(
            { id: vm.sessionData.accountId },
            function success(results: any) {
                vm.accountFriends = results.data.Friends;
            },
            function failure(err: any) {
                console.log(err);
            }
        );

        $('.sidebar').addClass('hidden');
        vm.$scope.home.showTgl = false;
        vm.hasProdVendor = vm.loginService.getSessionData().account.productionServicesVendorId;
        vm.useTapIngest = vm.loginService.getSessionData().account.tapIngestFlag;

        vm._loadData(false, false);
    }

    /* IMPLEMENTATION : BINDABLE */
    downloadAsset(uuid: string, type: string, alias: string) {
        let vm = this;

        if (uuid === undefined) {
            return;
        }

        alias = alias.replace('__', '_');

        vm.NotificationService.showNotificationToast('Download(s) has started.');

        // Build download links
        let endpoint = vm.EndPointService.assetDownloadEndpoint + '/';

        vm.$window.open(
            endpoint +
            '?' +
            'assetUuid=' +
            uuid +
            '&' +
            'baseFilename=' +
            alias +
            '&' +
            'authorization=' +
            vm.loginService.getJwt()
        );
    }

    viewMetadataReport() {
        let vm = this;

        if (
            !vm.spot.AssetMap.QC_REPORT_DATA ||
            vm.spot.AssetMap.QC_REPORT_DATA.length < 1
        ) {
            return;
        }

        return vm.$mdDialog.show({
            controller: 'MetadataReportController',
            controllerAs: 'vm',
            template: require('../view/metadataReportDialog-template.html'),
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            fullscreen: true, // For small screens only
            resolve: {
                reportData() {
                    return vm.spot.AssetMap.QC_REPORT_DATA[0];
                },
            },
        });
    }

    endDateFilter(date: any) {
        return moment(date).isSameOrAfter(moment().startOf('day'));
    }

    viewHistoryReport() {
        let vm = this;

        // Make request to get __sessionKey
        vm.AuthenticationResourceFactory.getSessionKey(
            {},
            function (sessionKey: any) {
                // Append __sessionKey to request to Node (instead of RE)
                vm.$window.open(
                    vm.EndPointService.assetDownloadReportEndpoint +
                    '/' +
                    vm.spot.id +
                    '/' +
                    vm.spot.isci +
                    '?sessionKey=' +
                    sessionKey.data,
                    '_blank'
                );

                // NODE: Take __sessionKey and pipe request to RE
                // NODE: Open request directly into response to stream data back to browser
            },
            function () {
                // Unable to retrieve the session key, maybe the user is logged out.
            }
        );
        return true;
    }

    convertProxy() {
        let vm = this;

        if (vm.spot) {
            vm.polling = true;
            vm.SpotResourceFactory.generateProxy({ id: vm.spot.id }, {}).subscribe(
                (asset: any) => {
                    vm.NotificationService.showNotificationToast(asset);

                    // Start listening for the current spot to be transcoded
                    vm._listenForProxy();
                },
                (err: any) => {
                    vm.NotificationService.showNotificationToast(err);
                }
            );
        }
    }

    openThumbnailDialog(thumbnailIndex: any) {
        let vm = this;

        vm.$mdDialog.show({
            controller: 'ThumbnailDialogController',
            controllerAs: 'vm',
            template: require('../view/thumbnailDialog-template.html'),
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            locals: {
                storyBoard: vm.assetMap.SPOT_STORYBOARD,
                thumbnailIndex,
            },
        });
    }

    openMenu($mdMenu: any) {
        $mdMenu.open();
    }

    loadAdvertisers(searchName: string) {
        let vm = this;

        return new Promise((resolve) => {
            vm.AdvertiserResourceFactory.getAll(
                {
                    agencyId: vm.spot && vm.spot.Agency && vm.spot.Agency.id !== '' ? vm.spot.Agency.id : null,
                    providerAccountId: vm.spot.providerAccountId ? vm.spot.providerAccountId : null,
                    active: true,
                    name: searchName,
                    limit: 15,
                }
            ).subscribe(
                (advertisers: any) => {
                    //If necessary, filtering to only advertisers with a blank Agency
                    if (!vm.spot.Agency || !vm.spot.Agency.id || vm.spot.Agency.id === '') {
                        advertisers = vm.$filter('filter')(advertisers, {
                            agencyId: null,
                        });
                    }
                    resolve(advertisers);
                },
                (err: any) => {
                    resolve([]);
                    console.debug(err);
                }
            );
        });
    }

    loadAgencies(nameSearch: string) {
        let vm = this;

        return new Promise((resolve, reject) => {
            vm.AgencyResourceFactory.getAll(
                {
                    name: nameSearch,
                    activeOnly: true,
                    limit: 15,
                }
            ).subscribe(
                function success(agencies: any) {
                    resolve(agencies.rows);
                },
                function failure(err: any) {
                    resolve([]);
                    console.debug(err);
                }
            );
        })
    }

    loadBrands(nameSearch: string) {
        let vm = this;

        return new Promise((resolve, reject) => {
            if (vm.spot.Advertiser.id !== '') {
                vm.BrandsResourceFactory.getAll(
                    {
                        advertiserId: vm.spot && vm.spot.Advertiser ? vm.spot.Advertiser.id : null,
                        name: nameSearch,
                        active: true,
                        limit: 15,
                    }
                ).subscribe(
                    (brands: any) => {
                        resolve(brands);
                    },
                    (err: any) => {
                        resolve([]);
                        console.debug(err);
                    }
                );
            }
        })
    }

    openSystemEvents() {
        let vm = this;

        if (vm.spot && vm.spot.id) {
            vm.$mdDialog.show({
                controller: 'SystemEventsDialogController',
                controllerAs: 'vm',
                template: require('../common/dialogs/systemEventsDialog-template.html'),
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                fullscreen: true,
                locals: {
                    id: vm.spot.id,
                    type: 'spot',
                },
            });
        }
    }

    openSpotRejected() {
        let vm = this;

        if (vm.spot && vm.spot.id) {
            vm.$mdDialog.show({
                controller: 'SpotRejectionsDialogController',
                controllerAs: 'vm',
                template: require('../common/dialogs/spotRejectionDialog-template.html'),
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                fullscreen: true,
                locals: {
                    id: vm.spot.id,
                    type: 'spot',
                },
            });
        }
    }

    toggleArchive() {
        let vm = this;

        if (vm.spot) {
            // This will set the flag to the opposite of what it is now.
            let confirmOrNot;

            if (!vm.spot.archived) {
                let confirm = vm.$mdDialog
                    .confirm()
                    .title('Would you like to continue?')
                    .textContent(
                        "Archiving an item will remove it from the default view. It will not be deleted but will be excluded in searches unless you select 'Include archives'."
                    )
                    .ariaLabel(
                        "Archiving an item will remove it from the default view. It will not be deleted but will be excluded in searches unless you select 'Include archives'."
                    )
                    .ok('Continue')
                    .cancel('Cancel');

                confirmOrNot = vm.$mdDialog.show(confirm);
            } else {
                confirmOrNot = vm.$q.when(true);
            }

            confirmOrNot.then(
                () => {
                    // User confirmed the archive function
                    vm.spot.archived = !vm.spot.archived;

                    vm.SpotResourceFactory.save({ id: vm.spot.id }, vm.spot); // We don't need to do anything here
                },
                () => {
                    // User cancelled the archive function so don't do anything
                }
            );
        }
    }

    selectPromo() {
        let vm = this;

        if (vm.spot.promo) {
            vm.spot.validTo = moment().add(1, 'months').format('MM/DD/YYYY');
        }
    }

    changePromoType(ev: any) {
        let vm = this;

        if (vm.spot.airStatus === 'UNFINISHED') {
            // Appending dialog to document.body to cover sidenav in docs app
            let confirm = vm.$mdDialog
                .confirm()
                .title('Promo Type Change')
                .textContent(
                    'This spot might not be suitable as a ready to air spot.  Do you wish to continue?” '
                )
                .ariaLabel('Promo type change to "unfinished"')
                .targetEvent(ev)
                .ok('Yes')
                .cancel('No');

            vm.$mdDialog.show(confirm).then(
                function () {
                    // Do nothing, because they're okay with it
                },
                function () {
                    vm.spot.airStatus = 'RTA';
                }
            );
        }
    }

    changeAgency() {
        let vm = this;

        if (
            vm.spot.Agency === null ||
            (
                vm.spot.Advertiser &&
                vm.spot.Advertiser.agencyId !== vm.spot.Agency.id
            )
        ) {
            vm.spot.Advertiser = null;
            vm.changeAdvertiser();
        }
    }

    changeAdvertiser() {
        let vm = this;

        // If an advertiser with no ID is selected somehow, just blow it away
        if (vm.spot.Advertiser && vm.spot.Advertiser.id === '') {
            vm.spot.Advertiser = null;
        }

        // If the advertiser has been cleared, or if the brand no longer matches, clear the brand out
        if (
            vm.spot.Advertiser === null ||
            (
                vm.spot.Brand &&
                vm.spot.Brand.advertiserId !== (vm.spot.Advertiser && vm.spot.Advertiser.id ? vm.spot.Advertiser.id : null)
            )
        ) {
            vm.spot.Brand = null;
            vm.changeBrand();
        }
    }

    changeBrand() {
        let vm = this;

        // If a bad brand is selected somehow, clear it out
        if (vm.spot.Brand && vm.spot.Brand.id === '') {
            vm.spot.Brand = null;
        }
    }

    validateDuration() {
        let vm = this;

        if (vm.spot) {
            // vm.convertDuration();
            for (let i = 0; i < vm.sessionData.durations.length; i++) {
                let filteredValue = vm.$filter('timeDuration')(
                    vm.sessionData.durations[i]
                );
                if (filteredValue === vm.filteredDuration) {
                    vm.validDuration = true;
                    return;
                }
            }
            vm.validDuration = false;
        } else {
            vm.validDuration = true;
        }
    }

    overrideIngest(ev: any) {
        let vm = this;

        vm.$mdDialog
            .show({
                controller: 'overrideIngestDialogController',
                controllerAs: 'vm',
                template: require('./overrideIngestDialog-template.html'),
                parent: angular.element(document.body),
                clickOutsideToClose: false,
                fullscreen: true, // For small screens only
                resolve: {
                    spot() {
                        return vm.spot;
                    },
                },
            })
            .then(
                function saved(savedValues: any) {
                    // Update values
                    vm.spot.trimmingOption = savedValues.trimmingOption;
                    vm.spot.leadingBlackFrameCount = parseInt(
                        savedValues.leadingBlackFrameCount + '',
                        10
                    );
                    vm.spot.slateFrameCount = parseInt(
                        savedValues.slateFrameCount + '',
                        10
                    );
                    vm.spot.postSlateBlackFrameCount = parseInt(
                        savedValues.postSlateBlackFrameCount + '',
                        10
                    );
                    vm.spot.trailingBlackFrameCount = parseInt(
                        savedValues.trailingBlackFrameCount + '',
                        10
                    );

                    // Notify users of important information
                    vm.$mdDialog.show(
                        vm.$mdDialog
                            .alert()
                            .parent(angular.element(document.body))
                            .clickOutsideToClose(false)
                            .title('Override Ingest Settings')
                            .textContent(
                                'When the spot is saved, overriden ingest settings will be used to reprocess the spot.'
                            )
                            .ariaLabel(
                                'When the spot is saved, overriden ingest settings will be used to reprocess the spot.'
                            )
                            .ok('OK')
                            .targetEvent(ev)
                    );
                },
                function cancelled() {
                    // Do nothing, the user cancelled the dialog
                }
            );
    }

    resetSpotDetails() {
        let vm = this;

        vm._loadData(false, false);
    }

    uploadMedia(uploadedFile: any) {
        let vm = this;

        if (!vm.loginService.isAdmin() && vm.assetMap.DISTRIBUTION_MASTER.length >= 1) {
            vm.NotificationService.showNotificationToast(
                'Spot has a Distribution Master file, so new media is not allowed.'
            );
            return;
        }
        if (vm.spot.id !== -1) {
            let checkLinkedSDPromise = vm.$q.defer();
            vm._checkLinkedSD(checkLinkedSDPromise);

            checkLinkedSDPromise.promise.then(function () {
                vm.uploading = true;
                // Create a new unique id for this upload
                let uploadId = 'UPLOAD' + new Date().getTime();

                // Create a new Flow object that will be used to upload the file
                vm.fileToUpload = new Flow({
                    target: vm.EndPointService.spotUploadEndPoint, // stickyUrl+
                    headers() {
                        let accountHash = JSON.parse(
                            vm.$window.localStorage[vm._authConstants.session.ACCOUNT_HASH]
                        );
                        let sessionData = JSON.parse(
                            vm.$window.sessionStorage[vm._authConstants.session.SESSION_DATA]
                        );

                        return {
                            Authorization:
                                'Bearer ' + accountHash[sessionData.accountId],
                        };
                    },
                    testMethod: 'GET',
                    uploadMethod: 'POST',
                    withCredentials: true,
                    allowDuplicateUploads: true,
                    query: {
                        spotId: vm.spot.id,
                        adIdentifier: vm.spot.isci,
                        providerId:
                            vm.sessionData.accountType !== 'PRODUCTIONSERVICESVENDOR'
                                ? vm.sessionData.accountId
                                : vm.spot.providerAccountId,
                        accountType: vm.sessionData.accountType,
                        originalContentFilename: uploadedFile.name,
                        trackingId: uploadId,
                    },
                    testChunks: true, // This allows flow.js to make test calls to see which chunks have already been uploaded. So it can resume where it left off.
                });

                // Append some tracking info to the file after it's added to the upload queue
                vm.fileToUpload.on('fileAdded', function (file: any) {
                    vm.spotFileId = file.uniqueIdentifier;
                    file.spotId = vm.spot.id;
                    file.trackingId = uploadId;
                });

                // Add the spot file to the uploader
                vm.fileToUpload.addFile(uploadedFile);

                // Update the session storage to include the new active upload
                vm.submitManager.addActiveUpload({
                    spotId: vm.spot.id,
                    status: 'UPLOADING',
                    progress: 0,
                    flowId: vm.spotFileId,
                    trackingId: uploadId,
                });

                // Handle upload events
                vm.fileToUpload.off();
                // Once the upload completes, submit the metadata
                vm.fileToUpload.on('fileError', function (file: any) {
                    vm.submitManager
                        .removeActiveUpload(file.flowObj.opts.query.trackingId)
                        .then(function (newUploadList: any) {
                            vm.activeUploads = newUploadList;
                        });
                    vm.uploading = false;
                });

                // Track the file's upload progress
                vm.fileToUpload.on('fileProgress', function (file: any) {
                    vm.submitManager
                        .updateActiveUpload(file.trackingId, {
                            progress: Math.floor(file.progress() * 100),
                        })
                        .then(function (newUploadList: any) {
                            vm.activeUploads = newUploadList;
                        });
                    vm.spot.progress = Math.floor(file.progress() * 100);
                    vm.spot.statusBar = vm.StatusService.readSpotStatus(vm.spot);
                    if (file.progress() === 1) {
                        vm.uploading = false;
                        vm.spot.progress = null;
                        vm.pollForFlip = vm.$interval(function () {
                            vm._loadData(false, false);
                            vm.storyBoard = [];
                        }, 120000);
                    }
                });

                // Begin the upload
                vm.fileToUpload.upload();
                vm.spot.oldStatus = vm.spot.status;
                vm.spot.status = 'UPLOADING';
                vm.spot.statusBar = vm.StatusService.readSpotStatus(vm.spot);
                vm.NotificationService.showNotificationToast('Spot Upload Started');
            });
        }
    }

    uploadMediaWithTap(uploadedFile: any) {
        let vm = this;

        vm.uploading = true;
        // Create a new unique id for this upload
        let uploadId = 'UPLOAD' + new Date().getTime();

        vm.fileToUpload = new Flow({
            target: vm.EndPointService.tapUploadEndPoint,
            headers() {
                let accountHash = JSON.parse(
                    vm.$window.localStorage[vm._authConstants.session.ACCOUNT_HASH]
                );
                let sessionData = JSON.parse(
                    vm.$window.sessionStorage[vm._authConstants.session.SESSION_DATA]
                );

                return {
                    Authorization: 'Bearer ' + accountHash[sessionData.accountId],
                };
            },
            testMethod: false,
            fileParameterName: 'file',
            uploadMethod: 'POST',
            withCredentials: true,
            allowDuplicateUploads: true,
            // 5Gb chunks, should force everything to go at once, since it's limited to that max size
            chunkSize: 5 * 1024 * 1024 * 1024,
            singleFile: true,
            query: {
                ignoreLoadingBar: true,
                waitForAutoConversions: false,
            },
            testChunks: false,
        });

        // Append some tracking info to the file after it's added to the upload queue
        vm.fileToUpload.on('fileAdded', function (file: any) {
            vm.spotFileId = file.uniqueIdentifier;
            file.spotId = vm.spot.id;
        });

        // Add the spot file to the uploader
        vm.fileToUpload.addFile(uploadedFile);

        // Update the session storage to include the new active upload
        vm.submitManager.addActiveUpload({
            spotId: vm.spot.id,
            status: 'UPLOADING',
            progress: 0,
            flowId: vm.spotFileId,
            trackingId: uploadId,
        });

        // Handle upload events
        vm.fileToUpload.off();
        // Once the upload completes, submit the metadata
        vm.fileToUpload.on('fileError', function (file: any) {
            vm.submitManager
                .removeActiveUpload(file.flowObj.opts.query.trackingId)
                .then(function (newUploadList: any) {
                    vm.activeUploads = newUploadList;
                });
            vm.uploading = false;
        });

        // Track the file's upload progress
        vm.fileToUpload.on('fileProgress', function (file: any) {
            vm.submitManager
                .updateActiveUpload(file.trackingId, {
                    progress: Math.floor(file.progress() * 100),
                })
                .then(function (newUploadList: any) {
                    vm.activeUploads = newUploadList;
                });
            vm.spot.progress = Math.floor(file.progress() * 100);
            vm.spot.statusBar = vm.StatusService.readSpotStatus(vm.spot);
            if (file.progress() === 1) {
                vm.uploading = false;
                vm.spot.progress = null;
                vm.pollForFlip = vm.$interval(function () {
                    vm._loadData(false, false);
                    vm.storyBoard = [];
                }, 120000);
            }
        });

        vm.fileToUpload.upload();
        vm.spot.oldStatus = vm.spot.status;
        vm.spot.status = 'UPLOADING';
        vm.spot.statusBar = vm.StatusService.readSpotStatus(vm.spot);
        vm.NotificationService.showNotificationToast('Spot Upload Started');

        let newAssetId: any;
        vm.fileToUpload.on('fileSuccess', function (file: any, message: any) {
            newAssetId = message;
        });

        vm.fileToUpload.on('complete', function () {
            // So that rejected spots get their upload icon back
            vm.spotFile = null;

            // Begin MIE Ingest
            vm.SpotResourceFactory.ingestUploadedMediaWithTap({ id: vm.spot.id, assetContentId: newAssetId }, {}).subscribe(
                () => {
                    vm.spot.status = 'UPLOADED';
                    vm.spot.statusBar = vm.StatusService.readSpotStatus(vm.spot);
                    // Notify the user that ingest has started
                    vm.NotificationService.showNotificationToast('Starting ingest');
                },
                (err: any) => {
                    console.log(err);
                    // Notify the user that ingest has failed
                    vm.NotificationService.showNotificationToast(
                        'Ingest failed',
                        err
                    );
                }
            );
        });
    }

    startUpload() {
        let vm = this;

        if (
            vm.asperaAccountDetails &&
            vm.asperaAccountDetails.asperaEnabled &&
            vm.asperaAccountDetails.asperaUserName &&
            vm.asperaAccountDetails.asperaPassword
        ) {
            vm.initAsperaUpload();
        } else {
            vm.$timeout(function () {
                let button: any = document.querySelector('#uploadMediaButton');
                button.click();
            });
        }
    }

    initAsperaUpload() {
        let vm = this;

        if (!vm.loginService.isAdmin() && vm.assetMap.DISTRIBUTION_MASTER.length >= 1) {
            vm.NotificationService.showNotificationToast(
                'Spot has a Distribution Master file, so new media is not allowed.'
            );
            return;
        }
        if (vm.spot.id !== -1) {
            let checkLinkedSDPromise = vm.$q.defer();
            vm._checkLinkedSD(checkLinkedSDPromise);
            checkLinkedSDPromise.promise.then(function () {
                let transferSpec = {
                    paths: [],
                    remote_host: 'aspera.comcastwholesale.com',
                    remote_user: vm.asperaAccountDetails.asperaUserName,
                    remote_password: vm.asperaAccountDetails.asperaPassword,
                    direction: 'send',
                    target_rate_kbps: 10000,
                    resume: 'sparse_checksum',
                    destination_root: '',
                    ssh_port: 33001,
                    fasp_port: 33001,
                };
                vm.AsperaService.handleUpload(transferSpec, vm.$scope, vm.spot);
            });
        }
    }

    cancelUpload() {
        let vm = this;

        if (
            vm.asperaAccountDetails &&
            vm.asperaAccountDetails.asperaEnabled &&
            vm.asperaEventObj
        ) {
            vm.AsperaService.handleCancel(vm.asperaEventObj);
            vm.$state.reload();
        } else {
            vm.cancelImport();
        }
    }

    cancelImport() {
        let vm = this;

        if (
            vm.fileToUpload &&
            vm.fileToUpload.isUploading &&
            vm.fileToUpload.isUploading()
        ) {
            vm.fileToUpload.cancel();
            vm._stopIngest(true, false);
        } else {
            // Upload completed already, make call to cancel CWE ingest
            vm.SpotResourceFactory.cancelIngest({ id: vm.spot.id }).subscribe(
                (spotDetails: any) => {
                    vm._stopIngest(true, spotDetails.status);
                    vm.NotificationService.showNotificationToast('Spot Import Cancelled');
                },
                () => {
                    // Unable to cancel job (likely because it already finished)
                    vm._stopIngest(false, false);
                }
            );
        }
    }

    cancel() {
        let vm = this;

        vm.$state.go('spotList', {}, { notify: true });
    }

    saveAndAddAnother() {
        let vm = this;

        vm.saveSpot(true, false);
    }

    saveSpot(loadNew: any, notify: any) {
        let vm = this;

        loadNew = loadNew === undefined ? false : loadNew;
        notify = notify === undefined ? true : notify;

        // If this is an HD spot in an auto-downconvert account, this will check for existing SD isci before saving.
        let checkIsciPromise = vm.$q.defer();
        vm._checkDownconvertIsci(checkIsciPromise);

        let checkLinkedSDPromise = vm.$q.defer();
        vm._checkLinkedSD(checkLinkedSDPromise);

        return checkIsciPromise.promise.then(function () {
            return checkLinkedSDPromise.promise.then(function () {
                let savePromise = vm.$q.defer();
                if (vm.newSpot) {
                    vm.spot.id = -1;
                    delete vm.spot.status;
                } else {
                    if (
                        vm.spot.Agency &&
                        vm.spot.Agency.id !== null &&
                        vm.spot.Agency.id !== '' &&
                        vm.spot.Agency.id !== undefined
                    ) {
                        vm.spot.agency = { id: vm.spot.Agency.id };
                        vm.spot.agencyId = vm.spot.Agency.id;
                    } else {
                        vm.spot.agency = null;
                        vm.spot.agencyId = null;
                        delete vm.spot.Agency;
                    }

                    if (
                        vm.spot.Advertiser &&
                        vm.spot.Advertiser.id !== null &&
                        vm.spot.Advertiser.id !== '' &&
                        vm.spot.Advertiser.id !== undefined
                    ) {
                        vm.spot.advertiser = {
                            id: vm.spot.Advertiser.id,
                        };
                        vm.spot.advertiserId = vm.spot.Advertiser.id;
                    } else {
                        vm.spot.advertiser = null;
                        vm.spot.advertiserId = null;
                        delete vm.spot.Advertiser;
                    }

                    if (
                        vm.spot.Brand &&
                        vm.spot.Brand.id !== null &&
                        vm.spot.Brand.id !== '' &&
                        vm.spot.Brand.id !== undefined
                    ) {
                        vm.spot.brand = { id: vm.spot.Brand.id };
                        vm.spot.brandId = vm.spot.Brand.id;
                    } else {
                        vm.spot.brand = null;
                        vm.spot.brandId = null;
                        delete vm.spot.Brand;
                    }

                    vm.spot.expectedDuration =
                        vm.spot.expectedDuration && vm.spot.expectedDuration != ''
                            ? vm.spot.expectedDuration
                            : null;
                }
                // Make the duration palatable for Node and then put it back after saving
                vm.convertDuration();
                savePromise = vm._doSave(loadNew, notify);
                vm.formatDuration();

                return savePromise.$promise;
            });
        });
    }

    unlockSpot() {
        let vm = this;

        vm.SpotResourceFactory.unlock({ id: vm.spot.id }, {}).subscribe(
            () => {
                vm.$state.reload();
            },
            (err: any) => {
                vm.NotificationService.showNotificationToast(err);
            }
        );
    }

    expireSpot() {
        let vm = this;

        let confirm = vm.$mdDialog
            .confirm()
            .title('Expire Spot?')
            .htmlContent(
                'This promo will be immediately expired and unavailable to any receiver.<br/><br/>If you wish to make it available again, you must update the Valid To date<br/>and send it again in another order.  Do you want to continue?'
            )
            .ariaLabel('Expire Spot')
            .ok('Yes, Expire Spot')
            .cancel('No');

        vm.$mdDialog.show(confirm).then(
            function () {
                vm.SpotResourceFactory.recall({ id: vm.spot.id }, {}).subscribe(
                    () => {
                        vm.NotificationService.showNotificationToast(
                            'Spot expired and recalled'
                        );
                        vm.$state.reload();
                    },
                    (err: any) => {
                        vm.NotificationService.showNotificationToast(err);
                    }
                );
            },
            function () { }
        );
    }

    showStats() {
        let vm = this;

        vm.$mdDialog.show({
            controller: 'spotStatisticsDialogController',
            controllerAs: 'vm',
            template: require('./spotStatisticsDialog-template.html'),
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            fullscreen: true, // For small screens only
            resolve: {
                spot() {
                    return vm.spot;
                },
            },
        });
    }

    convertDuration() {
        let vm = this;

        let newDuration;
        let totalSeconds = 0;
        if (
            typeof vm.spot.expectedDuration === 'string' &&
            vm.spot.expectedDuration.indexOf(':') > -1
        ) {
            newDuration = vm.spot.expectedDuration
                ? vm.spot.expectedDuration.split(':')
                : [];
            if (newDuration.length > 1) {
                for (let i = 0; i < newDuration.length - 1; i++) {
                    newDuration[i] *= 60;
                }
                for (let j = 0; j < newDuration.length; j++) {
                    totalSeconds += parseInt(newDuration[j], 10);
                }
                vm.spot.expectedDuration = totalSeconds;
            } else {
                vm.spot.expectedDuration = newDuration[0];
            }
        }
    }

    formatDuration() {
        let vm = this;

        vm.filteredDuration = vm.$filter('timeDuration')(vm.spot.expectedDuration);
        //Needed for completely proper formatting and validation, especially when a single number
        // (e.g. '3') is entered
        if (vm.filteredDuration) {
            let formatCheck = vm.filteredDuration.split(':');
            if (
                formatCheck[0].split('').length === 1 &&
                formatCheck[0].split('')[0] !== '0'
            ) {
                vm.filteredDuration = '0' + vm.filteredDuration;
            }
            vm.validateDuration();
        }
    }

    openAssign() {
        let vm = this;

        return vm.$mdDialog
            .show({
                controller: AssignDialogController,
                controllerAs: 'vm',
                template: require('./assignDialog-template.html'),
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                fullscreen: true, // For small screens only
                locals: {
                    spotIsci: vm.spot.isci,
                },
            })
            .then(
                function (successData: any) {
                    vm.assign = successData;
                    vm.assignSpot();
                },
                function (cancelData: any) {
                    console.log(cancelData);
                }
            );
    }

    assignSpot() {
        let vm = this;

        let assignObject: any = {
            spotId: vm.spot.id,
            friendAccountId: vm.assign.friend,
        };

        if (vm.assign.agency) {
            assignObject.agencyId = vm.assign.agency;
        }

        if (vm.assign.advertiser) {
            assignObject.advertiserId = vm.assign.advertiser;
        }

        if (vm.assign.brand) {
            assignObject.brandId = vm.assign.brand;
        }

        vm.SpotResourceFactory.assign(assignObject).subscribe(
            () => {
                vm.NotificationService.showNotificationToast(
                    'Spot ' + vm.spot.isci + ' successfully assigned'
                );
                vm._loadData(false, false);
            },
            (failure: any) => {
                vm.NotificationService.showNotificationToast(
                    'Spot assignment failed',
                    failure
                );
            }
        );
    }

    checkFriends() {
        let vm = this;

        vm.SecurityAccountResourceFactory.findFriendOfProviders(
            { id: vm.sessionData.accountId },
            function success(results: any) {
                vm.isFriendOf = results.data.FriendsOf.length > 0;
            },
            function failure(err: any) {
                console.log(err);
            }
        );
    }

    disableSave() {
        let vm = this;

        let notQcVendor =
            vm.sessionData.accountType !== 'QCVENDOR' &&
            vm.spot &&
            vm.spot.status !== 'PENDING_QC';

        if (vm.modifiedNotes) {
            return false;
        }
        if (!vm.$scope.spotDetailForm.$valid) {
            return true;
        }
        if (!vm.loginService.hasPermission(['spot.modify']) && notQcVendor) {
            return true;
        }
        if (!vm.metadataEditable() && notQcVendor) {
            return true;
        }
        if (
            vm.sessionData.isFilteredUser &&
            !(vm.spot.Advertiser.id || vm.spot.Agency.id)
        ) {
            return true;
        }

        return angular.equals(vm.originalSpot, vm.spot) && !vm.modifiedNotes;
    }
    disableReset() {
        let vm = this;
        if (!vm.$scope.spotDetailForm.$valid) {
            return true;
        }

        if (vm.modifiedNotes) {
            return false;
        }

        return angular.equals(vm.originalSpot, vm.spot) && !vm.modifiedNotes;
    }

    changeFormat() {
        let vm = this;

        vm.spot.hdFlag = vm.spot.format === 'HD';
        switch (vm.spot.format) {
            case "RADIO":
                vm.spot.mediaType = 'AUDIO';
                break;
            case "HD":
            case "SD":
                vm.spot.mediaType = 'VIDEO';
                break;
            case "COMPOSITE":
                vm.spot.mediaType = null;
                break;
        }
        vm._buildIsciValidator();
    }

    deleteSpot() {
        let vm = this;

        vm.SpotResourceFactory.delete({ id: vm.spot.id }).subscribe(
            () => {
                vm.NotificationService.showNotificationToast(
                    'Spot ' + vm.spot.isci + ' successfully deleted'
                );
                vm.$state.go('spotList', {}, { notify: true });
            },
            (failure: any) => {
                vm.NotificationService.showNotificationToast(
                    'Could not delete spot',
                    failure
                );
            }
        );
    }

    addFriends() {
        let vm = this;

        return vm.$mdDialog
            .show({
                controller: 'FriendsDialogController',
                controllerAs: 'vm',
                template: require('./friendsDialog-template.html'),
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                fullscreen: true, // For small screens only
                locals: {
                    currentFriends: vm.spot.Friends,
                    accountFriends: vm.accountFriends,
                },
            })
            .then(
                (successData: any) => {
                    if (!vm.spot.id) {
                        vm.saveSpot(false, false).then(function () {
                            vm._buildFriendList(successData);
                        });
                    } else {
                        vm._buildFriendList(successData);
                    }
                },
                () => {
                    //user cancelled the popup
                }
            );
    }

    reprocessSpot() {
        let vm = this;

        vm.SpotResourceFactory.reprocess({ spotId: vm.spot.id }).subscribe(
            () => {
                vm.NotificationService.showNotificationToast('Spot reprocessing begun');
            },
            (failure: any) => {
                vm.NotificationService.showNotificationToast(
                    'Spot reprocess failed',
                    failure
                );
            }
        );
    }

    reslateSpot() {
        let vm = this;

        vm.reloadingSpotStatus = false;
        // Only reslate if the spot is saved and not changed
        if (vm.disableSave()) {
            vm.SpotResourceFactory.reslate(
                {
                    spotId: vm.spot.id,
                    useTapIngest: vm.useTapIngest,
                }).subscribe(
                    () => {
                        vm.NotificationService.showNotificationToast('Spot reslating started');
                        if (vm.useTapIngest) {
                            vm.spot.status = 'PROCESSING';
                            vm.spot.statusBar = vm.StatusService.readSpotStatus(vm.spot);
                            vm.$timeout(() => {
                                vm._loadData(false, true);
                                vm.storyboard = [];
                            }, 10000);
                        } else {
                            vm.reloadingSpotStatus = true;
                            vm.$timeout(() => {
                                vm._loadData(false, false);
                                vm.storyBoard = [];
                            }, 3000);
                        }
                    },
                    (failure: any) => {
                        vm.NotificationService.showNotificationToast(
                            'Spot reslating failed',
                            failure
                        );
                    }
                );
        } else {
            vm.NotificationService.showNotificationToast(
                'Please save your changes before starting a reslate'
            );
        }
    }

    reconvertFromHd() {
        let vm = this;

        let confirm = vm.$mdDialog
            .confirm()
            .title('Reconvert Spot from HD?')
            .textContent(
                'By reconverting from HD you will lose any changes made to the existing SD spot.'
            )
            .ariaLabel('Reconvert Spot from HD')
            .ok('Yes, Reconvert from HD Spot')
            .cancel('No');

        vm.$mdDialog.show(confirm).then(
            function () {
                vm.SpotResourceFactory.relink({ spotId: vm.spot.id }, {}).subscribe(
                    () => {
                        vm.NotificationService.showNotificationToast(
                            'Spot was successfully relinked.'
                        );
                        vm.$state.reload();
                    },
                    (err: any) => {
                        vm.NotificationService.showNotificationToast(
                            'An error occurred trying to relink to original spot',
                            err
                        );
                    }
                );
            },
            function () { }
        );
    }

    changeDownConvertPref() {
        let vm = this;

        if (!vm.newSpot) {
            vm.SpotResourceFactory.changeDownconvertPreference(
                {
                    spotId: vm.spot.id,
                    downConvertPreference: vm.spot.downConvertPreference,
                }).subscribe(
                    () => { },
                    (failure: any) => {
                        vm.NotificationService.showNotificationToast(
                            'Error changing down convert preference',
                            failure
                        );
                    }
                );
        }
    }

    sendToProdSvcs() {
        let vm = this;

        let stateCheck = vm._confirmBadProdSvcsState(false);

        if (stateCheck.confirm) {
            stateCheck.promise.then(
                function () {
                    //Don't go to prod svcs if we alerted the user about incomplete metadata
                    if (!stateCheck.sendToPsv) {
                        return true;
                    }
                    let confirm = vm.$mdDialog
                        .alert()
                        .title('Production Services')
                        .textContent('The spot has been sent to Production Services')
                        .ariaLabel('The spot has been sent to Production Services')
                        .ok('OK');

                    vm.$mdDialog.show(confirm).then(
                        function () {
                            vm.SpotResourceFactory.sendToProdSvcs({ id: vm.spot.id }, {}).subscribe(
                                () => {
                                    vm.NotificationService.showNotificationToast(
                                        'Spot was successfully sent to prod services.'
                                    );
                                    vm.$state.reload();
                                },
                                (err: any) => {
                                    vm.NotificationService.showNotificationToast(
                                        'An error occurred when sending the spot to prod services',
                                        err
                                    );
                                }
                            );
                        },
                        function () { }
                    );
                },
                function () {
                    // Cancelled, do nothing
                }
            );
        } else {
            let confirm = vm.$mdDialog
                .alert()
                .title('Production Services')
                .textContent('The spot has been sent to Production Services')
                .ariaLabel('The spot has been sent to Production Services')
                .ok('OK');

            vm.$mdDialog.show(confirm).then(
                function () {
                    vm.SpotResourceFactory.sendToProdSvcs({ id: vm.spot.id }, {}).subscribe(
                        () => {
                            vm.NotificationService.showNotificationToast(
                                'Spot was successfully sent to prod services.'
                            );
                            vm.$state.reload();
                        },
                        (err: any) => {
                            vm.NotificationService.showNotificationToast(
                                'An error occurred when sending the spot to prod services',
                                err
                            );
                        }
                    );
                },
                function () { }
            );
        }
    }

    copySpotProxy() {
        let vm = this;

        return vm.$mdDialog
            .show({
                controller: 'CopySpotProxyDialogController',
                controllerAs: 'vm',
                template: require('./copySpotProxyDialog-template.html'),
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                fullscreen: false, // For small screens only
                locals: {
                    apiURL: vm.spot.apiURL,
                },
            })
            .then(
                function () {
                    //User closed popup
                },
                function () {
                    //user cancelled the popup
                }
            );
    }

    metadataEditable() {
        let vm = this;

        if (!vm || !vm.spot) {
            return false;
        }

        // Status is in an editable state
        let editableStatus =
            [
                'DRAFT',
                'PENDING_MEDIA',
                'PENDING_META',
                'PENDING_QC',
                'PENDING_PROD_SVCS',
                'REJECTED',
                'CANCELLED',
                'UNSENT',
            ].indexOf(vm.spot.status) > -1;

        // User is the owner OR a friend OR the spot is new, which means you're the owner (the `==` is on purpose, because sometimes ids are strings
        let ownerOrFriend =
            (vm.spot.ProviderAccount &&
                vm.sessionData.accountId == vm.spot.ProviderAccount.id) ||
            vm.isFriend ||
            vm.newSpot;

        return editableStatus && ownerOrFriend;
    }

    canCopySpotProxy() {
        let vm = this;

        if (!vm.spot) {
            return false;
        }

        if (
            !vm.loginService.hasPermission(['spot.modify']) &&
            vm.sessionData.accountType !== 'PRODUCTIONSERVICESVENDOR'
        ) {
            return false;
        }

        if (
            !vm.assetMap ||
            (vm.assetMap.MEZZANINE.length < 1 && vm.spot.format === 'RADIO')
        ) {
            return false;
        }

        if (vm.assetMap.QT_PROXY.length < 1 && vm.spot.format !== 'RADIO') {
            return false;
        }

        // Status is in an editable state
        let editableStatus =
            [
                'DRAFT',
                'PENDING_MEDIA',
                'PENDING_META',
                'PENDING_QC',
                'PENDING_PROD_SVCS',
                'REJECTED',
                'CANCELLED',
                'UNSENT',
                'SENT',
            ].indexOf(vm.spot.status) > -1;

        // User is the owner OR a friend OR the spot is new, which means you're the owner (the `==` is on purpose, because sometimes ids are strings
        let ownerOrFriend =
            (vm.spot.ProviderAccount &&
                vm.sessionData.accountId == vm.spot.ProviderAccount.id) ||
            vm.isFriend ||
            vm.newSpot;

        return (
            editableStatus &&
            (ownerOrFriend || vm.sessionData.accountType === 'PRODUCTIONSERVICESVENDOR')
        );
    }

    getSpotIsci() {
        let vm = this;

        // Check for expressIsci first
        if (vm.spot.expressIsci) {
            return vm.spot.expressIsci;
        } else if (vm.spot.isci) {
            return vm.spot.isci;
        }

        return vm.capturedIsci;
    }

    retrieveOffsiteMedia() {
        let vm = this;

        vm.offsiteRetrieval = true;
        vm.AssetResourceFactory.retrieveOffsiteMedia(
            { id: vm.spot.MainAsset.AssetContent.id },
            function success() {
                // Retrieval has been started
                vm.NotificationService.showNotificationToast(
                    'Offsite media has been requested.'
                );
                vm.offsiteRetrieval = false;
                vm.$state.reload();
            },
            function failure() {
                // Retrieval has failed to start
                vm.NotificationService.showNotificationToast(
                    'Offsite media request has failed.'
                );
                vm.offsiteRetrieval = false;
                vm.$state.reload();
            }
        );
    }

    determineOffsiteStatus() {
        let vm = this;

        let originalStatus = 'LOCAL';
        let DMStatus = 'LOCAL';

        if (
            vm.spot &&
            vm.spot.OrigAsset &&
            vm.spot.OrigAsset.AssetContent &&
            vm.spot.OrigAsset.AssetContent.offsiteStorageState
        ) {
            originalStatus = vm.spot.OrigAsset.AssetContent.offsiteStorageState;
        }
        if (
            vm.spot &&
            vm.spot.DMAsset &&
            vm.spot.DMAsset.AssetContent &&
            vm.spot.DMAsset.AssetContent.offsiteStorageState
        ) {
            DMStatus = vm.spot.DMAsset.AssetContent.offsiteStorageState;
        }

        // Figure out what's actually going on
        if (
            originalStatus === 'MOVING_OFFSITE_FAILURE' ||
            DMStatus === 'MOVING_OFFSITE_FAILURE'
        ) {
            return 'MOVING_OFFSITE_FAILURE';
        }
        if (
            originalStatus === 'MOVING_LOCAL_FAILURE' ||
            DMStatus === 'MOVING_LOCAL_FAILURE'
        ) {
            return 'MOVING_LOCAL_FAILURE';
        }
        if (originalStatus === 'MOVING_OFFSITE' || DMStatus === 'MOVING_OFFSITE') {
            return 'MOVING_OFFSITE';
        }
        if (originalStatus === 'MOVING_LOCAL' || DMStatus === 'MOVING_LOCAL') {
            return 'MOVING_LOCAL';
        }
        if (originalStatus === 'OFFSITE' || DMStatus === 'OFFSITE') {
            return 'OFFSITE';
        }

        return 'LOCAL';
    }

    acceptSpot() {
        let vm = this;

        vm.SpotResourceFactory.applyQc(
            { id: vm.spot.id },
            { result: 'PASS', notes: '' }
        ).subscribe(
            () => {
                vm.NotificationService.showNotificationToast(
                    'Quality check applied for Spot: ' + vm.spot.id
                );
                vm.$state.reload();
            },
            (err: any) => {
                vm.NotificationService.showNotificationToast(
                    'Applying QC for ' +
                    (vm.spot.title ? vm.spot.title : "'no title'") +
                    ' failed',
                    err
                );
                console.log(err);
            }
        );
    }

    rejectSpot() {
        let vm = this;

        vm.$mdDialog
            .show({
                controller: QcRejectionDialogController,
                controllerAs: 'vm',
                template: require('./dialogs/qcRejectionDialog-template.html'),
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                locals: {},
            })
            .then(
                function okay(response: any) {
                    vm.SpotResourceFactory.applyQc(
                        { id: vm.spot.id },
                        {
                            result: 'FAIL',
                            notes: response.reason + ' - ' + response.notes,
                        }
                    ).subscribe(
                        () => {
                            vm.NotificationService.showNotificationToast(
                                'Quality check applied for Spot: ' + vm.spot.id
                            );
                            vm.$state.reload();
                        },
                        (err: any) => {
                            vm.NotificationService.showNotificationToast(
                                'Applying QC for ' +
                                (vm.spot.title ? vm.spot.title : "'no title'") +
                                ' failed',
                                err
                            );
                            console.log(err);
                        }
                    );
                },
                function cancel() {
                    // Do nothing, the user cancelled
                }
            );
    }

    requestCC() {
        let vm = this;

        let stateCheck = vm._confirmBadProdSvcsState(true);

        if (stateCheck.confirm) {
            stateCheck.promise.then(
                function () {
                    //Break out if the user has incomplete metadata
                    if (stateCheck.metadataAlert) {
                        return true;
                    }
                    vm.$mdDialog
                        .show({
                            controller: RequestCCDialogController,
                            controllerAs: 'vm',
                            template: require('./dialogs/requestCCDialog-template.html'),
                            parent: angular.element(document.body),
                            clickOutsideToClose: true,
                            locals: {
                                spotId: vm.spot.id,
                                emailAddress: vm.sessionData.emailAddress,
                            },
                        })
                        .then(
                            function okay(response: any) {
                                if (response === true) {
                                    vm.$state.reload();
                                }
                            },
                            function cancel() {
                                // Do nothing, the user cancelled
                            }
                        );
                },
                function () {
                    // Cancelled, do nothing
                }
            );
        } else {
            vm.$mdDialog
                .show({
                    controller: RequestCCDialogController,
                    controllerAs: 'vm',
                    template: require('./dialogs/requestCCDialog-template.html'),
                    parent: angular.element(document.body),
                    clickOutsideToClose: true,
                    locals: {
                        spotId: vm.spot.id,
                        emailAddress: vm.sessionData.emailAddress,
                        firstName: vm.sessionData.firstName,
                        lastName: vm.sessionData.lastName,
                    },
                })
                .then(
                    function okay(response: any) {
                        if (response === true) {
                            vm.$state.reload();
                        }
                    },
                    function cancel() {
                        // Do nothing, the user cancelled
                    }
                );
        }
    }

    expandThumbnails(showPane: any) {
        let vm = this;

        vm.showThumbnailStrip = showPane;

        if (showPane) {
            if (vm.storyBoard.length === 0) {
                vm.loadThumbnails();
            }
        }
    }

    async checkAdId() {
        let vm = this;

        // Immediately ensure that the loading icon is set to false (in case it isn't a valid Ad-ID isci)
        vm.adIdLoading = false;
        // Store the form reference on the controller, since $scope loses it by the end of this chain
        vm.detailForm = vm.$scope.spotDetailForm;

        let viewVal = vm.$scope.spotDetailForm.isciInput.$viewValue;
        vm.nonFormatIsci = false;
        if (
            viewVal &&
            viewVal.length > 10 &&
            viewVal.length < 13 &&
            (viewVal.match(/^[0-9a-zA-Z]{11}[HhDd]$/) ||
                viewVal.match(/^[0-9a-zA-Z]{11}$/))
        ) {
            vm.nonFormatIsci = false;
            vm.adIdLoading = true;
            vm.reloadOnFailure = false;
            let newSave = vm._checkForNewSave();
            // if it's new we need to save it first
            return newSave.then(
                function success() {
                    return vm.SpotResourceFactory.getAdIDMetadata({ isci: viewVal }).subscribe(
                        (response: any) => {
                            vm.adIdLoading = false;

                            // show popup with returned metadata, and save it if the user says to.
                            return vm.$mdDialog
                                .show({
                                    controller: 'AdIdMetadataDialogController',
                                    controllerAs: 'vm',
                                    template: require('./adIdMetadataDialog-template.html'),
                                    parent: angular.element(document.body),
                                    clickOutsideToClose: false,
                                    fullscreen: true, // For small screens only
                                    locals: {
                                        metadata: response,
                                    },
                                })
                                .then(
                                    (successData: any) => {
                                        // Set the adidFlag on the spot
                                        successData.adidFlag = true;

                                        // need to do this because if you just straight pass the string back, it doesn't properly account for UTC time shift.
                                        if (successData.validFrom) {
                                            successData.validFrom[0] = moment
                                                .utc(successData.validFrom[0])
                                                .format('YYYY-MM-DD');
                                        }
                                        if (successData.validTo) {
                                            successData.validTo[0] = moment
                                                .utc(successData.validTo[0])
                                                .format('YYYY-MM-DD');
                                        }
                                        return vm.SpotResourceFactory.saveAdIDMetadata(
                                            {
                                                meta: successData,
                                                spotId: vm.spot.id,
                                            }
                                        ).subscribe(
                                            () => {
                                                // Prevent the unsaved changes dialog from catching this
                                                vm.detailForm.$setPristine();
                                                vm.$state.reload();
                                            },
                                            (err: any) => {
                                                vm.NotificationService.showNotificationToast(
                                                    err,
                                                    err.message
                                                );
                                            }
                                        );
                                    },
                                    () => {
                                        if(vm.newSpot) {
                                            vm.$state.reload();
                                        }
                                        // never you mind
                                        vm.NotificationService.showNotificationToast(
                                            'The metadata provided by Ad-ID has not been saved to the spot.'
                                        );
                                    }
                                );
                        },
                        () => {
                            // Do nothing, because we're being stealthy
                            vm.reloadOnFailure = true;
                            vm.adIdLoading = false;
                        }
                    );
                },
                function () {
                    // never you mind
                    vm.adIdLoading = false;
                }
            );
        } else {
            vm.nonFormatIsci = true;
            // If it ain't Ad-ID, don't fix it
            vm.spot.adidFlag = false;
            return true;
        }
    }

    preventEnter(event: any, type: any) {
        let vm = this;

        if (
            event.keyCode === 13 &&
            ((vm.newAgency === false && type === 'agency') ||
                (vm.newAdvertiser === false && type === 'advertiser') ||
                (vm.newBrand === false && type === 'brand'))
        ) {
            event.preventDefault();
        }
        if (event.keyCode !== 13 && type === 'agency') {
            vm.newAgency = false;
        }
        if (event.keyCode !== 13 && type === 'advertiser') {
            vm.newAdvertiser = false;
        }
        if (event.keyCode !== 13 && type === 'brand') {
            vm.newBrand = false;
        }
    }

    /* IMPLEMENTATION : PRIVATE */
    // All private methods should start with '_' in order to distinguish them
    _stopIngest(revertStatus: any, newStatus: any) {
        let vm = this;

        vm.uploading = false;
        vm.spotFile = null;
        vm.currentProgress = null;
        vm.spot.progress = null;

        if (revertStatus) {
            vm.spot.status = newStatus === undefined ? vm.spot.oldStatus : newStatus;
            vm.spot.statusBar = vm.StatusService.readSpotStatus(vm.spot);
        }

        vm.submitManager.removeActiveUpload(vm.spot.id).then(function (newUploadList: any) {
            vm.activeUploads = newUploadList;
        });
    }

    async _checkForNewSave() {
        return new Promise(async (resolve, reject) => {
            if (this.newSpot) {
                this.SpotResourceFactory.getProviderSpots({ isci: this.spot.isci }).subscribe(
                    async (duplicateSpotInfo: any) => {
                        if (duplicateSpotInfo.count > 0) {
                            return reject('ISCI Already exists in account');
                        }

                        // if it's new we need to try to save it first.
                        try {
                            await this.saveSpot(false, false);
                        } catch (err) {
                            return reject('Failed to save spot before applying Ad-ID');
                        }

                        return resolve('success');
                    }
                );
            } else {
                return resolve('success');
            }
        });
    }

    _checkDownconvertIsci(deferPromise: any) {
        let vm = this;

        if (
            vm.sessionData.account.automaticHdDownConvert &&
            vm.spot.hdFlag &&
            (vm.newSpot || vm.isciChanged)
        ) {
            let hdIsci = vm.spot.isci.toUpperCase();
            let sdIsci = '';

            if (hdIsci.slice(hdIsci.length - 1, hdIsci.length) === 'H') {
                sdIsci = hdIsci.substring(0, hdIsci.length - 1);

                vm.SpotResourceFactory.verify({ isci: sdIsci }).subscribe(
                    (result: any) => {
                        if (result) {
                            let confirm = vm.$mdDialog
                                .confirm()
                                .title('Existing SD Spot Found')
                                .textContent(
                                    'An SD version of this spot already exists in this account.  No HD to SD down-convert will be created.'
                                )
                                .ariaLabel('Existing SD Spot Found')
                                .ok('Continue');

                            vm.$mdDialog.show(confirm).then(function () {
                                deferPromise.resolve('success');
                            });
                        } else {
                            deferPromise.resolve('success');
                        }
                    }
                );
            } else {
                deferPromise.resolve('success');
            }
        } else {
            deferPromise.resolve('success');
        }
    }

    _checkLinkedSD(deferPromise: any) {
        let vm = this;
        let origSpot: any;
        let curSpot: any;

        // If we are attempting to update a linked SD spot, warn them that it will unlink it from the HD.
        if (!vm.newSpot) {
            origSpot = angular.copy(vm.originalSpot);
            curSpot = angular.copy(vm.spot);
            delete origSpot.encodedFlag;
            delete curSpot.encodedFlag;
            delete origSpot.runningFootage;
            delete curSpot.runningFootage;
        }

        if (
            !vm.newSpot &&
            !vm.spot.hdFlag &&
            vm.spot.SDSpotLink &&
            vm.spot.SDSpotLink.linkedFlag &&
            !angular.equals(origSpot, curSpot)
        ) {
            let confirm = vm.$mdDialog
                .confirm()
                .title('Linked SD Spot')
                .textContent(
                    'This is a linked spot.  Updating this spot will unlink it from the HD spot it was created from.  Would you like to continue updating?'
                )
                .ariaLabel('Linked SD Spot')
                .ok('Update')
                .cancel('Cancel');

            vm.$mdDialog.show(confirm).then(
                function cont() {
                    deferPromise.resolve('success');
                },
                function cancel() {
                    deferPromise.reject();
                }
            );
        } else {
            deferPromise.resolve('success');
        }
    }

    _doSave(loadNew: boolean, notify: boolean) {
        let vm = this;

        vm.spot.isci = vm.spot.isci.toUpperCase();
        // If the expected duration field gets touched on accident, just ignore it
        vm.spot.expectedDuration =
            vm.spot.expectedDuration === '' ? null : vm.spot.expectedDuration;

        if (vm.spot.runningFootage === null || !vm.spot.runningFootage) {
            delete vm.spot.runningFootage;
        }

        // Create an array to hold each "to date" in a moment.js object
        let maxDateArray = [];
        // Create an array to hold the rights dates back in their db-ready form
        let tempDatesArray = [];
        for (let type in vm.spot.RightsDates) {
            if (vm.spot.RightsDates.hasOwnProperty(type)) {
                // track only existing dates to populate the runEndDate with the max
                if (vm.spot.RightsDates[type].to) {
                    maxDateArray.push(moment(vm.spot.RightsDates[type].to));
                }

                // Add the rights date object to the array that we'll use for saving
                tempDatesArray.push({
                    spot: vm.spot && vm.spot.id ? vm.spot.id : null,
                    type,
                    validFromDate: vm.spot.RightsDates[type].from
                        ? vm.spot.RightsDates[type].from
                        : null,
                    validToDate: vm.spot.RightsDates[type].to
                        ? vm.spot.RightsDates[type].to
                        : null,
                });
            }
        }

        // Re-assign the rights date array back to the spot, so it "looks" like we got it from the DB
        vm.spot.RightsDates = tempDatesArray;

        // Update runEndDate, but ONLY if the multi rights dates are enabled and exist
        if (vm.sessionData.account.enableMultiRightsDates && maxDateArray.length > 0) {
            vm.spot.runEndDate = moment.max(maxDateArray).set({
                hour: 0,
                minute: 0,
                second: 0,
                millisecond: 0,
            });
        }

        notify = notify === undefined ? true : notify;

        let previousStatus = vm.spot.status;
        let previousReslate = vm.spot.reslateFlag;

        return vm.SpotResourceFactory.make({}, vm.spot).subscribe(
            (response: any) => {
                vm.spot.id = response;

                if (notify) {
                    vm.NotificationService.showNotificationToast(
                        'Spot ' +
                        (vm.spot.isci ? vm.spot.isci : "'no isci'") +
                        ' successfully saved'
                    );
                }
                vm.$rootScope.$broadcast(vm.EventConstants.spotDetail.save);

                if (loadNew) {
                    vm._loadNewSpot(false);
                } else {
                    vm.SpotResourceFactory.checkReslate({ id: vm.spot.id }).subscribe(
                        (response: any) => {
                            // Check to see if the newly updated spot has need of a reslate, and that it wasn't already in a "reslate needed" state
                            if (
                                (previousReslate !== response.needsReslate || previousStatus !== 'UNSENT') &&
                                response.needsReslate &&
                                vm.spot.title !== '' &&
                                vm.spot.format !== 'COMPOSITE' &&
                                vm.spot.format !== 'RADIO' &&
                                (vm.spot.originalAssetId || vm.spot.AssetMap.DISTRIBUTION_MASTER.length > 0)
                            ) {
                                // Set the form to pristine for the reslate method to look at
                                vm.$scope.spotDetailForm.$setPristine();
                                vm.originalSpot = angular.copy(vm.spot);

                                // Ask the user if they want to reslate now
                                let confirm = vm.$mdDialog
                                    .confirm()
                                    .title('Reslate will be needed.')
                                    .textContent(
                                        'It appears that some slate fields have been changed. Would you like to reslate this spot now?'
                                    )
                                    .ariaLabel(
                                        'It appears that some slate fields have been changed. Would you like to reslate this spot now?'
                                    )
                                    .ok('Yes')
                                    .cancel('No');

                                return vm.$mdDialog.show(confirm).then(
                                    function () {
                                        vm.reslateSpot();
                                    },
                                    function () {
                                        vm.$state.reload();
                                    }
                                );
                            } else {
                                vm.$scope.spotDetailForm.$setPristine();

                                if (vm.$stateParams.id === undefined || vm.$stateParams.id === '') {
                                    if(vm.reloadOnFailure || vm.nonFormatIsci) {
                                        vm.$state.go('spotDetail', { id: vm.spot.id });
                                    } else {
                                        vm.$state.go('.', { id: vm.spot.id }, {notify: false});
                                    }
                                } else {
                                    vm.$state.reload();
                                }
                            }
                        },
                        (err: any) => {
                            console.log(err);
                        }
                    );
                }
            },
            (err: any) => {
                vm.NotificationService.showNotificationToast(
                    'Spot save for ' +
                    (vm.spot.title ? vm.spot.title : "'no title'") +
                    ' failed',
                    err
                );
                console.log(err);
            }
        );
    }

    _loadAssociatedData(spot: any) {
        let vm = this;

        // Return them in a promise, so we can wait for them to finish before moving on
        return vm.$q.all([
            vm.EnumService.getEnum('Spot', 'unusualAudioType').then(
                function (audioTypes: any) {
                    vm.unusualAudios = audioTypes;
                },
                function () {
                    vm.unusualAudios = [];
                }
            ),
            vm.EnumService.getEnum('Spot', 'format').then(
                function (formats: any) {
                    vm.formats = formats;
                },
                function () {
                    vm.formats = [];
                }
            ),
            vm.EnumService.getEnum('Spot', 'airStatus').then(
                function (airStatuses: any) {
                    vm.promoTypes = airStatuses;
                },
                function () {
                    vm.promoTypes = [];
                }
            ),
            vm.EnumService.getEnum('Spot', 'downConvertPreference').then(
                function (preferences: any) {
                    vm.downConvertPreferences = preferences;
                },
                function () {
                    vm.downConvertPreferences = [];
                }
            ),
        ]);
    }

    _listenForProxy() {
        let vm = this;

        vm.socket.on('HTML5 Transcode For Spot ' + vm.spot.id, function (data: any) {
            if (data.html5TranscodeStatus === 'COMPLETED') {
                vm.assetMap.HTML5_PROXY.push(data.proxyAsset);

                // Retrieve the new Proxy asset to load into view
                if (
                    vm.assetMap &&
                    vm.assetMap.HTML5_PROXY &&
                    vm.assetMap.HTML5_PROXY[0] &&
                    !bowser.msie &&
                    !bowser.msedge &&
                    !bowser.safari
                ) {
                    vm.AssetResourceFactory.getVideoAsset(
                        { uuid: vm.assetMap.HTML5_PROXY[0] },
                        {},
                        function success(asset: any) {
                            vm.proxyAsset = URL.createObjectURL(asset.data.data); // IE10+
                            vm.proxyType = 'video/webm';
                            vm.polling = false;
                        },
                        function failure(err: any) {
                            console.log(err);
                            vm.polling = false;
                        }
                    );
                } else if (
                    vm.assetMap &&
                    vm.assetMap.FLV_PROXY &&
                    vm.assetMap.FLV_PROXY[0] &&
                    (bowser.msie || bowser.msedge || bowser.safari)
                ) {
                    vm.AssetResourceFactory.getVideoAsset(
                        { uuid: vm.assetMap.FLV_PROXY[0] },
                        {},
                        function success(asset: any) {
                            vm.proxyAsset = URL.createObjectURL(asset.data.data); // IE10+
                            vm.proxyType = 'video/mp4';
                            vm.polling = false;
                        },
                        function failure(err: any) {
                            console.log(err);
                        }
                    );
                }
            }
        });
    }

    _loadData(loadThumbs: boolean, loadQuiet: boolean) {
        let vm = this;

        loadThumbs = loadThumbs === undefined ? false : loadThumbs;
        // Load the initially required values
        vm._loadAssociatedData(undefined);
        // If Promo-only, default it
        if (
            (vm.$stateParams.id === undefined || vm.$stateParams.id === '') &&
            (!vm.spot || vm.spot.isNew)
        ) {
            vm.$interval.cancel(vm.pollForFlip);
            vm._loadNewSpot(true);
        } else {
            vm.SpotResourceFactory.get(
                {
                    id: vm.$stateParams.id ? vm.$stateParams.id : vm.spot.id,
                    loadQuiet
                }
            ).subscribe(
                (spot: any) => {
                    vm._loadAssociatedData(vm.spot).then(function () {
                        vm.airStatus = spot.airStatus;
                        vm.spot = angular.copy(spot);
                        vm.capturedIsci = vm.spot.isci;
                        vm.capturedId = vm.spot.id;
                        // This is only used when reslating spots, it does nothing otherwise
                        vm.reloadingSpotStatus = false;
                        vm.spot.statusBar = vm.StatusService.readSpotStatus(vm.spot);
                        vm.spot.Agency = vm.spot.Agency ? vm.spot.Agency : undefined;
                        vm.spot.Advertiser = vm.spot.Advertiser ? vm.spot.Advertiser : undefined;
                        vm.spot.Brand = vm.spot.Brand ? vm.spot.Brand : undefined;
                        vm.spot.Friends = vm.spot.Friends ? vm.spot.Friends : {};
                        vm.spot.autoDownConvert = vm.spot.autoDownConvert
                            ? vm.spot.autoDownConvert
                            : 'CENTERCUT';
                        vm.spot.airStatus = vm.spot.airStatus
                            ? vm.spot.airStatus
                            : 'there was nothing';

                        // Unfortunately, dates are hard. Different browsers and tech will try to use either local or GMT timezones
                        // The below will create a new date object (in whatever timezone the device or browser prefers) and then assign it the right year/month/day
                        // This needs to be done for both valid dates.
                        if (vm.spot.runStartDate && vm.spot.runStartDate.split) {
                            let startDateParts = vm.spot.runStartDate.split('-');
                            vm.spot.runStartDate = new Date(parseInt(startDateParts[0], 10), parseInt(startDateParts[1], 10) - 1, parseInt(startDateParts[2], 10));
                        } else {
                            vm.spot.runStartDate = null;
                        }
                        if (vm.spot.runEndDate && vm.spot.runEndDate.split) {
                            let endDateParts = vm.spot.runEndDate.split('-');
                            vm.spot.runEndDate = new Date(parseInt(endDateParts[0], 10), parseInt(endDateParts[1], 10) - 1, parseInt(endDateParts[2], 10));
                        } else {
                            vm.spot.runEndDate = null;
                        }

                        vm.assetMap = vm.spot.AssetMap;
                        vm.formatDuration();

                        // If the rights dates object has values, we need to convert those suckers to an object we can
                        // assign models to in the template
                        if (vm.spot.RightsDates && vm.spot.RightsDates.length > 0) {
                            let tempDateHolder: any = {};

                            for (let i = 0; i < vm.spot.RightsDates.length; i++) {
                                tempDateHolder[vm.spot.RightsDates[i].type] = {
                                    from: vm.spot.RightsDates[i].validFromDate,
                                    to: vm.spot.RightsDates[i].validToDate,
                                };
                            }

                            // Convert rights dates into an object
                            vm.spot.RightsDates = {
                                BROADCAST: {
                                    spot: vm.spot.id,
                                    from:
                                        tempDateHolder.BROADCAST &&
                                            tempDateHolder.BROADCAST.from
                                            ? tempDateHolder.BROADCAST.from
                                            : null,
                                    to:
                                        tempDateHolder.BROADCAST &&
                                            tempDateHolder.BROADCAST.to
                                            ? tempDateHolder.BROADCAST.to
                                            : null,
                                },
                                DIGITAL: {
                                    spot: vm.spot.id,
                                    from:
                                        tempDateHolder.DIGITAL &&
                                            tempDateHolder.DIGITAL.from
                                            ? tempDateHolder.DIGITAL.from
                                            : null,
                                    to:
                                        tempDateHolder.DIGITAL &&
                                            tempDateHolder.DIGITAL.to
                                            ? tempDateHolder.DIGITAL.to
                                            : null,
                                },
                                NEWMEDIA: {
                                    spot: vm.spot.id,
                                    from:
                                        tempDateHolder.NEWMEDIA &&
                                            tempDateHolder.NEWMEDIA.from
                                            ? tempDateHolder.NEWMEDIA.from
                                            : null,
                                    to:
                                        tempDateHolder.NEWMEDIA &&
                                            tempDateHolder.NEWMEDIA.to
                                            ? tempDateHolder.NEWMEDIA.to
                                            : null,
                                },
                                INDUSTRIAL: {
                                    spot: vm.spot.id,
                                    from:
                                        tempDateHolder.INDUSTRIAL &&
                                            tempDateHolder.INDUSTRIAL.from
                                            ? tempDateHolder.INDUSTRIAL.from
                                            : null,
                                    to:
                                        tempDateHolder.INDUSTRIAL &&
                                            tempDateHolder.INDUSTRIAL.to
                                            ? tempDateHolder.INDUSTRIAL.to
                                            : null,
                                },
                            };
                        } else {
                            // If there are no rights dates available, create a blank version of the created object
                            // We may not need this normally, but we should still have it, since we talk about it in the template
                            vm.spot.RightsDates = {
                                BROADCAST: {
                                    spot: vm.spot.id,
                                    from: null,
                                    to: null,
                                },
                                DIGITAL: {
                                    spot: vm.spot.id,
                                    from: null,
                                    to: null,
                                },
                                NEWMEDIA: {
                                    spot: vm.spot.id,
                                    from: null,
                                    to: null,
                                },
                                INDUSTRIAL: {
                                    spot: vm.spot.id,
                                    from: null,
                                    to: null,
                                },
                            };
                        }

                        // Only poll if the spot is not in a "terminal" status
                        if (
                            [
                                'CANCELLED',
                                'REJECTED',
                                'SENT',
                                'PENDING_MEDIA',
                                'UNSENT',
                                'PENDING_META',
                                'PENDING_QC',
                                'PENDING_PROD_SVCS',
                            ].indexOf(vm.spot.status) > -1
                        ) {
                            vm.$interval.cancel(vm.pollForFlip);
                        }

                        // Build the ISCI validation Regex
                        if (vm.spot.ProviderAccount.strictIsci) {
                            vm._buildIsciValidator();
                        }

                        if (vm.spot.html5TranscodeStatus == 'TRANSCODING') {
                            vm.polling = true;
                            vm._listenForProxy();
                        }

                        if (vm.spot.duration > vm.spot.expectedDuration) {
                            vm.deliveriesStatuses.COMPLETED.status =
                                'Long Duration Delivered';
                        }
                        if (vm.spot.html5TranscodeStatus == 'FAILED') {
                            vm.displayError = true;
                            vm.assetMap.HTML5_PROXY = [];
                        } else if (vm.spot.format === 'RADIO') {
                            // RADIO SPOTS
                            vm.AssetResourceFactory.getAudioAsset(
                                { uuid: vm.assetMap.MEZZANINE[0] },
                                {},
                                function success(asset: any) {
                                    vm.proxyAsset = URL.createObjectURL(
                                        asset.data.data
                                    ); // IE10+
                                    vm.proxyType = 'audio/mp3';
                                },
                                function failure(err: any) {
                                    console.log(err);
                                }
                            );
                        } else if (
                            vm.assetMap &&
                            vm.assetMap.HTML5_PROXY &&
                            vm.assetMap.HTML5_PROXY[0] &&
                            !bowser.msie &&
                            !bowser.msedge &&
                            !bowser.safari
                        ) {
                            // SMART BROWSER VIDEO ASSETS
                            vm.AssetResourceFactory.getVideoAsset(
                                {
                                    uuid: vm.assetMap.HTML5_PROXY[0],
                                },
                                {},
                                function success(asset: any) {
                                    vm.proxyAsset = URL.createObjectURL(
                                        asset.data.data
                                    ); // IE10+
                                    vm.proxyType = 'video/webm';
                                },
                                function failure(err: any) {
                                    console.log(err);
                                }
                            );
                        } else if (
                            vm.assetMap &&
                            vm.assetMap.FLV_PROXY &&
                            vm.assetMap.FLV_PROXY[0] &&
                            (bowser.msie || bowser.msedge || bowser.safari)
                        ) {
                            // LESS SMART BROWSER VIDEO ASSETS
                            vm.AssetResourceFactory.getVideoAsset(
                                { uuid: vm.assetMap.FLV_PROXY[0] },
                                {},
                                function success(asset: any) {
                                    vm.proxyAsset = URL.createObjectURL(
                                        asset.data.data
                                    ); // IE10+
                                    vm.proxyType = 'video/mp4';
                                },
                                function failure(err: any) {
                                    console.log(err);
                                }
                            );
                        }
                        vm.checkFriends();
                        // if it has a MEZZANINE but not an html5Asset, it needs to be converted
                        // if it has NEITHER proxy, then it probably hasn't been ingested yet/is rejected/etc.
                        vm.needsConversion =
                            vm.assetMap &&
                                vm.assetMap.MEZZANINE &&
                                vm.assetMap.MEZZANINE.length > 0
                                ? !(
                                    vm.assetMap.HTML5_PROXY &&
                                    vm.assetMap.HTML5_PROXY[0]
                                )
                                : false;

                        if (loadThumbs) {
                            // Do the thumbnail loading (move this?)
                            vm.loadThumbnails();
                        }
                        vm.originalSpot = angular.copy(vm.spot);
                        vm.isFriend =
                            vm.sessionData.accountId != vm.spot.ProviderAccount.id;
                        vm.notNewAndIsFriend = !vm.newSpot && vm.isFriend;
                    });
                },
                (error: any) => {
                    vm.NotificationService.showNotificationToast(
                        'Spot failed to load, connection to server may have been interrupted.  Please try logging out and back in.',
                        error
                    );
                }
            );
        }
    }

    loadThumbnails() {
        let vm = this;

        let spotStoryboard = angular.copy(vm.assetMap.SPOT_STORYBOARD);

        if (
            vm.assetMap &&
            vm.assetMap.SD_HD_SLATE_THUMBNAILS &&
            vm.assetMap.SD_HD_SLATE_THUMBNAILS.length
        ) {
            if (vm.spot.sixChannelFlag) {
                if (vm.assetMap.SD_HD_SLATE_THUMBNAILS.length > 1) {
                    spotStoryboard.unshift(vm.assetMap.SD_HD_SLATE_THUMBNAILS[1]);
                } else {
                    spotStoryboard.unshift(vm.assetMap.SD_HD_SLATE_THUMBNAILS[0]);
                }
            } else {
                spotStoryboard.unshift(vm.assetMap.SD_HD_SLATE_THUMBNAILS[0]);
            }
        }
        if (
            vm.assetMap &&
            vm.assetMap.ORIGINAL_SLATE_THUMBNAIL &&
            vm.assetMap.ORIGINAL_SLATE_THUMBNAIL.length
        ) {
            spotStoryboard.unshift(vm.assetMap.ORIGINAL_SLATE_THUMBNAIL[0]);
        }

        // We still need all these in the assetMap so we can load the correct thumbnails on click
        vm.assetMap.SPOT_STORYBOARD = angular.copy(spotStoryboard);

        vm.$q.all(
            spotStoryboard.map(function iterator(cur: any) {
                return vm.AssetResourceFactory.getImageAsset({ uuid: cur }, {}).$promise;
            })
        ).then(
            function success(storyboardImages: any) {
                vm.storyBoard = storyboardImages.map(function (img: any) {
                    let blob = new Blob([img.data], {
                        type: img.config['Content-Type'],
                    });
                    return URL.createObjectURL(blob);
                });
            },
            function failure(err: any) {
                console.log(err);
                vm.storyBoard = [];
            }
        );
    }

    _loadNewSpot(blankSpot: boolean) {
        let vm = this;

        vm.newSpot = true;
        if (blankSpot) {
            let defaultFormat = 'HD';
            if (
                vm.sessionData.account.disallowTv &&
                !vm.sessionData.account.allowPromo &&
                vm.sessionData.account.allowRadio
            ) {
                defaultFormat = 'RADIO';
            }
            vm.spot = {
                isNew: true,
                status: 'DRAFT',
                isci: '',
                promo: false,
                downConvertPreference:
                    vm.sessionData.account &&
                        vm.sessionData.account.downConvertPreference
                        ? vm.sessionData.account.downConvertPreference
                        : 'CENTERCUT',
                format: defaultFormat,
                hdFlag: defaultFormat === 'HD',
                mediaType: 'VIDEO',
                ProviderAccount: vm.sessionData.account,
            };
        } else {
            vm.spot = {
                isNew: false,
                Agency: vm.spot.Agency,
                Advertiser: vm.spot.Advertiser,
                Brand: vm.spot.Brand,
                format: vm.spot.format,
                hdFlag: vm.spot.hdFlag,
                status: 'DRAFT',
                promo: false,
                downConvertPreference: vm.spot.downConvertPreference,
                mediaType: vm.spot.mediaType,
            };
        }

        // If the rights dates object has values, we need to convert those suckers to an object we can
        // assign models to in the template
        if (vm.spot.RightsDates && vm.spot.RightsDates.length > 0) {
            let tempDateHolder: any = {};

            for (let i = 0; i < vm.spot.RightsDates.length; i++) {
                tempDateHolder[vm.spot.RightsDates[i].type] = {
                    from: vm.spot.RightsDates[i].validFromDate,
                    to: vm.spot.RightsDates[i].validToDate,
                };
            }

            // Convert rights dates into an object
            vm.spot.RightsDates = {
                BROADCAST: {
                    spot: vm.spot.id,
                    from:
                        tempDateHolder.BROADCAST && tempDateHolder.BROADCAST.from
                            ? tempDateHolder.BROADCAST.from
                            : null,
                    to:
                        tempDateHolder.BROADCAST && tempDateHolder.BROADCAST.to
                            ? tempDateHolder.BROADCAST.to
                            : null,
                },
                DIGITAL: {
                    spot: vm.spot.id,
                    from:
                        tempDateHolder.DIGITAL && tempDateHolder.DIGITAL.from
                            ? tempDateHolder.DIGITAL.from
                            : null,
                    to:
                        tempDateHolder.DIGITAL && tempDateHolder.DIGITAL.to
                            ? tempDateHolder.DIGITAL.to
                            : null,
                },
                NEWMEDIA: {
                    spot: vm.spot.id,
                    from:
                        tempDateHolder.NEWMEDIA && tempDateHolder.NEWMEDIA.from
                            ? tempDateHolder.NEWMEDIA.from
                            : null,
                    to:
                        tempDateHolder.NEWMEDIA && tempDateHolder.NEWMEDIA.to
                            ? tempDateHolder.NEWMEDIA.to
                            : null,
                },
                INDUSTRIAL: {
                    spot: vm.spot.id,
                    from:
                        tempDateHolder.INDUSTRIAL && tempDateHolder.INDUSTRIAL.from
                            ? tempDateHolder.INDUSTRIAL.from
                            : null,
                    to:
                        tempDateHolder.INDUSTRIAL && tempDateHolder.INDUSTRIAL.to
                            ? tempDateHolder.INDUSTRIAL.to
                            : null,
                },
            };
        } else {
            // If there are no rights dates available, create a blank version of the created object
            // We may not need this normally, but we should still have it, since we talk about it in the template
            vm.spot.RightsDates = {
                BROADCAST: {
                    spot: vm.spot.id,
                    from: null,
                    to: null,
                },
                DIGITAL: {
                    spot: vm.spot.id,
                    from: null,
                    to: null,
                },
                NEWMEDIA: {
                    spot: vm.spot.id,
                    from: null,
                    to: null,
                },
                INDUSTRIAL: {
                    spot: vm.spot.id,
                    from: null,
                    to: null,
                },
            };
        }

        vm.spot.statusBar = vm.StatusService.readSpotStatus(vm.spot);
        vm._buildIsciValidator();
    }

    _buildIsciValidator() {
        let vm = this;

        if (vm.spot.hdFlag) {
            vm.isciValidator = /^[a-zA-Z0-9]+[hH]$/;
        } else {
            vm.isciValidator = /^[a-zA-Z0-9]+[a-gi-zA-GI-Z0-9]$/;
        }
    }

    _buildFriendList(friends: any) {
        let vm = this;

        let friendList = '';
        if (friends.length > 0) {
            for (let i = 0; i < friends.length; i++) {
                if (i + 1 === friends.length) {
                    friendList += friends[i].id;
                } else {
                    friendList += friends[i].id + ',';
                }
            }
        }
        vm._submitFriends(friendList);
    }

    _submitFriends(friendList: any) {
        let vm = this;

        vm.SpotResourceFactory.setFriends(
            { id: vm.spot.id, friendAccounts: friendList },
        ).subscribe(
            () => {
                vm.NotificationService.showNotificationToast(
                    'Successfully updated friends'
                );
                vm._loadData(false, false);
                vm.$state.reload();
            },
            (err: any) => {
                vm.NotificationService.showNotificationToast(
                    'Updating friends failed',
                    err
                );
                console.log(err);
            }
        );
    }

    _confirmBadProdSvcsState(isRequestingCC: boolean) {
        let vm = this;

        let metadataSituation =
            vm.spot.title === '' ||
            !vm.spot.title ||
            !vm.spot.agencyId ||
            !vm.spot.advertiserId ||
            !vm.spot.brandId ||
            !vm.spot.isci;

        let badStatusSituation =
            vm.spot.status === 'PENDING_MEDIA' || vm.spot.status === 'PENDING_META';

        if (metadataSituation) {
            let popupText = isRequestingCC
                ? "This spot doesn't yet have all required metadata.  Please fill in title, agency, advertiser, brand and then click Request Closed Captioning again."
                : "This spot doesn't yet have all required metadata.  Please fill in title, agency, advertiser, brand and then resend to production services.";
            let confirm = vm.$mdDialog
                .alert()
                .title('Production Services Warning')
                .textContent(popupText)
                .ariaLabel(popupText)
                .ok('OK');

            return {
                confirm: true,
                promise: vm.$mdDialog.show(confirm),
                metadataAlert: true,
            };
        } else if (badStatusSituation) {
            let confirm = vm.$mdDialog
                .alert()
                .title('Production Services Warning')
                .textContent(
                    'This spot does not have media.  Please upload media to the spot and then click Request Closed Captioning again.'
                )
                .ariaLabel(
                    'This spot does not have media.  Please upload media to the spot and then click Request Closed Captioning again.'
                )
                .ok('OK');

            return {
                confirm: true,
                sendToPsv: !isRequestingCC,
                promise: isRequestingCC ? vm.$mdDialog.show(confirm) : vm.$q.resolve(true),
            };
        } else {
            return { confirm: false };
        }
    }
}
